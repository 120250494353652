<template>
  <div class="contact">
    <div class="contact-content">
      <div class="title">联系我们</div>
      <div class="content">
        <div class="text">
          <div class="subtitle">
            现在联系，获得一对一支付解决方案，专业顾问为您服务！
          </div>
          <div class="phone">售前客户经理 17828488209</div>
        </div>
        <div class="qrcode">
          <el-space :size="30">
            <div
              class="qrcode-item scroll-item animate__swing"
              data-wow-duration="2s"
              v-for="(item, index) in qrcodes"
              :key="index"
            >
              <img :src="item.image" alt="" />
              <span>{{ item.label }}</span>
            </div>
          </el-space>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shouqian from '@/assets/image/qrcode/shouqian.jpg'
import shouhou from '@/assets/image/qrcode/shouqian.jpg'
import gongzhonghao from '@/assets/image/qrcode/shouqian.jpg'
export default {
  name: 'contact-component',
  data() {
    return {
      qrcodes: [
        { image: shouqian, label: '售前咨询' },
        { image: shouhou, label: '售后咨询' },
        { image: gongzhonghao, label: '微信公众号' }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.contact {
  background: url('@/assets/image/about/contact.png');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  .contact-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    .title {
      font-size: 24px;
      font-weight: 700;
    }
    .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      .text {
        padding: 20px 0;
        width: 100%;
        font-size: 16px;
        line-height: 28px;
      }
      .qrcode {
        width: 100%;
        display: flex;
        justify-content: center;
        .qrcode-item {
          width: 100px;
          height: 100px;
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            height: 100%;
            width: 100%;
          }
          span {
            padding: 10px;
          }
        }
      }
    }
  }
}
</style>
